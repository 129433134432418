import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './Support.module.scss';

const Support = ({ intl: { formatMessage } }) => {
  return (
    <section className={styles.container_support}>
      <div className={styles.container_support__content_highlight}>
        <StaticImage
          src="../../../../../../images/highlight.webp"
          alt="highlight"
          loading="lazy"
          layout="constrained"
          formats={['auto', 'webp', 'avif']}
        />
      </div>
      <div className={styles.container_support__content_svg_support}>
        <StaticImage
          src="../../../../../../images/girlphone1.webp"
          alt="Call us"
          loading="lazy"
          layout="constrained"
          formats={['auto', 'webp', 'avif']}
          className={styles.container_support__content_svg_support__img}
        />
        <div className={styles.container_support__card_green}></div>
        <div className={styles.container_support__container_headphone}>
          <div
            className={
              styles.container_support__container_headphone__content_headphone
            }
          >
            <StaticImage
              src="../../../../../../images/headphone.webp"
              alt="headphone"
              loading="lazy"
              layout="constrained"
              formats={['auto', 'webp', 'avif']}
              className={
                styles.container_support__container_headphone__content_headphone__img
              }
            />
          </div>
          <div
            className={
              styles.container_support__container_headphone__content_text
            }
          >
            <p className={styles.container_support__container_headphone__text}>
              {formatMessage({ id: 'anyTimeSupport' })}
            </p>
            <p className={styles.container_support__container_headphone__small}>
              <a href="tel:+34 643916329">+34 643 91 63 29</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default injectIntl(Support);

Support.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};
