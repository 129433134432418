// extracted by mini-css-extract-plugin
export var body_background = "#f8f9fa";
export var container_support = "Support-module__container_support___YZ1LP";
export var container_support__card_green = "Support-module__container_support__card_green___HkQc6";
export var container_support__container_headphone = "Support-module__container_support__container_headphone___E0g+D";
export var container_support__container_headphone__content_headphone = "Support-module__container_support__container_headphone__content_headphone___2jY-d";
export var container_support__container_headphone__content_headphone__img = "Support-module__container_support__container_headphone__content_headphone__img___5skI-";
export var container_support__container_headphone__content_text = "Support-module__container_support__container_headphone__content_text___67rgM";
export var container_support__container_headphone__small = "Support-module__container_support__container_headphone__small___Gw7Ah";
export var container_support__container_headphone__text = "Support-module__container_support__container_headphone__text___VULYG";
export var container_support__content_highlight = "Support-module__container_support__content_highlight___N3rox";
export var container_support__content_svg_support = "Support-module__container_support__content_svg_support___XiDAm";
export var container_support__content_svg_support__img = "Support-module__container_support__content_svg_support__img___kwZeL";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var lg = "1200px";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "#2ed3ae";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var white = "#fff";
export var xl = "1536px";
export var xxl = "2500px";